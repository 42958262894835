import React from 'react';
import { Link } from 'react-router-dom';
import fordham from '../assets/fordham.png';
import logochallenge from '../assets/logoChallenge.png';

export default function Navbar() {
  return (
    <div className="w-full bg-white shadow">
      <nav className="container mx-auto flex items-center justify-between p-4 lg:p-6">
        {/* Left: Challenge Logo */}
        <div className="flex-shrink-0">
          <Link to="/">
            <img
              src={logochallenge}
              alt="Challenge Logo"
              className="w-16 lg:w-32" // Smaller size for challenge logo
            />
          </Link>
        </div>

        {/* Center: Fordham Logo */}
        <div className="flex-grow flex justify-center">
          <img src={fordham} alt="Fordham Logo" className="w-32 lg:w-80" />
        </div>
      </nav>

      {/* Centered Fordham Logo for smaller screens */}
      <div className="block lg:hidden text-center mt-4">
        <img src={fordham} alt="Fordham Logo" width={200} height={32} />
      </div>
    </div>
  );
}
