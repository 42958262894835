import React from 'react';
import ForgotPassword from '../views/ForgotPassword';
import walkingImg from '../../assets/walking.png'; 
import Navbar from '../../components/Navbar';

function ForgotPasswordLayout() {
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar/>

      <div className="flex-grow flex">
        <div className="relative w-1/2 h-[calc(100vh-100px)]">
          <img
            src={walkingImg}
            alt="Walking"
            className="absolute inset-0 w-full h-full object-cover z-0"
          />
        </div>
        <div className="flex-grow flex items-center justify-center bg-white p-6">
          <ForgotPassword />
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordLayout;
