import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";

 
export default function MainLayout() {
  return (
    <>
      <div className="layout-container ">
        <div className="layout-inner-container">
          <Sidebar/>
          <div className="app-body w-100 ">
            <div>
            <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}