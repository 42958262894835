import { firestore } from '../lib/firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getCookie } from "../utils/cookieUtil";
import { Timestamp } from 'firebase/firestore';

export const ExcelExport = async (startDay, endDay) => {
  
  try {

    const roles = getCookie('roleId');
    const permission = roles ? roles : '';
    
    if (startDay && endDay) {
      var dailyStepQuery = query(
        collection(firestore, 'Daily Steps'),
        where('day', '>=', Number(startDay)),
        where('day', '<=', Number(endDay))
      );
    } else if (!startDay || !endDay) {
      if (!startDay) startDay = '0';
      if (!endDay) endDay = '30';

    
      var dailyStepQuery = query(
        collection(firestore, 'Daily Steps'),
        where('day', '>=', Number(startDay)),
        where('day', '<=', Number(endDay))
      );
    } else {
      
      var dailyStepQuery = collection(firestore, 'Daily Steps');
    }


    
    

    // Fetch data from Firestore collections
    const userDocs = await getDocs(collection(firestore, 'Users'));
    const goalDocs = await getDocs(collection(firestore, 'Goals'));
    const dailyStepDocs = await getDocs(dailyStepQuery);
    const balanceDocs = await getDocs(collection(firestore, 'Cumulative Balance'));
    const reviewDocs = await getDocs(collection(firestore, 'Review'));
    const freshStartDocs = await getDocs(collection(firestore, 'Fresh Start'));

    const usersData = userDocs.docs.map(doc => doc.data());
    const goalsData = goalDocs.docs.map(doc => doc.data());
    const dailyStepsData = dailyStepDocs.docs.map(doc => doc.data());
    const balanceData = balanceDocs.docs.map(doc => doc.data());
    const reviewData = reviewDocs.docs.map(doc => doc.data());
    const freshStartData = freshStartDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }))

    // Review mapping
    const reviewMapping = {};
    reviewData.forEach(review => {
      const userId = review.userId;
      const reviewDay = review.day;
      if (!reviewMapping[userId]) reviewMapping[userId] = {};
      reviewMapping[userId][reviewDay] = review;
    });

    // Fresh Start dates mapping
    const freshStartMapping = {};
    freshStartData.forEach(freshStart => {
      const userId = freshStart.id;

      freshStart.dates.forEach(dateEntryMap => {
        Object.values(dateEntryMap).forEach(entry => {
          // Check if entry is a Timestamp directly or if it has a date field
          const dateToProcess = entry instanceof Timestamp ? entry : entry.date;

          if (dateToProcess && typeof dateToProcess.toDate === 'function') {
            const freshStartDate = dateToProcess.toDate().toISOString().split('T')[0];
            console.log(`Processing date for user ${userId}: ${freshStartDate}`);

            if (!freshStartMapping[userId]) freshStartMapping[userId] = [];
            freshStartMapping[userId].push(freshStartDate);
          } else {
            console.warn(`No valid date found in entry for user ${userId}`);
          }
        });
      });
    });

    console.log("Final fresh start dates mapping:", freshStartMapping);

    // Structure data
    const structuredData = [];
    let serialNumber = 1;

    usersData.forEach(user => {
      const userGoals = goalsData.find(goal => goal.uid === user.uid);
      const userDailySteps = dailyStepsData.filter(step => step.userId === user.uid);
      const userBalance = balanceData.find(balance => balance.userId === user.uid);

      userDailySteps.forEach(step => {
        const stepDay = step.day;
        const stepDate = step.timestamp.toDate().toISOString().split('T')[0];

        const reviewForDay = reviewMapping[user.uid] ? reviewMapping[user.uid][stepDay] : null;
        const freshStartDates = freshStartMapping[user.uid] || [];
        const matchingFreshStartDates = freshStartDates.filter(date => date === stepDate);

        const row = {
          SiNo: serialNumber++,
          UserID: user.uid,
          Group: user.group,
          DailyGoal: userGoals ? userGoals.Goal : '',
          GoalDate: userGoals ? userGoals.goalSettingDate.toDate().toISOString().split('T')[0] : '',
          Day: stepDay,
          DayDate: stepDate,
          DailySteps: step.Steps,
          CumulativeBalance: userBalance ? userBalance.balance : '',
          Feedback1: reviewForDay ? reviewForDay.question1 : '',
          Feedback2: reviewForDay ? reviewForDay.question2 : '',
          Feedback3: reviewForDay ? reviewForDay.question3 : '',
          FreshStartDates: matchingFreshStartDates.join(', '), 
        };

        // Conditionally add fields based on permission
        if (permission.includes("adduser")) {
          row.Email = user.email;
          row.FirstName = user.name.firstName;
          row.LastName = user.name.secondName;
        }

        structuredData.push(row);
      });
    });

    return structuredData;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw new Error('Failed to fetch data');
  }
};


// import { firestore } from '../lib/firebase';
// import { collection, getDocs, query, where } from 'firebase/firestore';
// import { getCookie } from "../utils/cookieUtil";
// import { Timestamp } from 'firebase/firestore';

// export const ExcelExport = async (startDay, endDay) => {
//   try {
//     const roles = getCookie('roleId');
//     const permission = roles ? roles : '';

//     // Query setup for daily steps based on date range
//     let dailyStepQuery;
//     if (startDay && endDay) {
//       dailyStepQuery = query(
//         collection(firestore, 'Daily Steps'),
//         where('day', '>=', Number(startDay)),
//         where('day', '<=', Number(endDay))
//       );
//     } else {
//       dailyStepQuery = query(
//         collection(firestore, 'Daily Steps'),
//         where('day', '>=', Number(startDay || '0')),
//         where('day', '<=', Number(endDay || '30'))
//       );
//     }

//     // Fetch data from Firestore collections
//     const userDocs = await getDocs(collection(firestore, 'Users'));
//     const goalDocs = await getDocs(collection(firestore, 'Goals'));
//     const dailyStepDocs = await getDocs(dailyStepQuery);
//     const balanceDocs = await getDocs(collection(firestore, 'Cumulative Balance'));
//     const reviewDocs = await getDocs(collection(firestore, 'Review'));
//     const freshStartDocs = await getDocs(collection(firestore, 'Fresh Start'));

//     const usersData = userDocs.docs.map(doc => doc.data());
//     const goalsData = goalDocs.docs.map(doc => doc.data());
//     const dailyStepsData = dailyStepDocs.docs.map(doc => doc.data());
//     const balanceData = balanceDocs.docs.map(doc => doc.data());
//     const reviewData = reviewDocs.docs.map(doc => doc.data());
//     const freshStartData = freshStartDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));

//     // Fresh Start dates mapping
//     const freshStartMapping = {};
//     freshStartData.forEach(freshStart => {
//       const userId = freshStart.id;

//       freshStart.dates.forEach(dateEntryMap => {
//         Object.values(dateEntryMap).forEach(entry => {
//           // Check if entry is a Timestamp directly or if it has a date field
//           const dateToProcess = entry instanceof Timestamp ? entry : entry.date;

//           if (dateToProcess && typeof dateToProcess.toDate === 'function') {
//             const freshStartDate = dateToProcess.toDate().toISOString().split('T')[0];
//             console.log(`Processing date for user ${userId}: ${freshStartDate}`);

//             if (!freshStartMapping[userId]) freshStartMapping[userId] = [];
//             freshStartMapping[userId].push(freshStartDate);
//           } else {
//             console.warn(`No valid date found in entry for user ${userId}`);
//           }
//         });
//       });
//     });

//     console.log("Final fresh start dates mapping:", freshStartMapping);

//     // Structure data
//     const structuredData = [];
//     let serialNumber = 1;

//     usersData.forEach(user => {
//       const userDailySteps = dailyStepsData.filter(step => step.userId === user.uid);

//       userDailySteps.forEach(step => {
//         const stepDate = step.timestamp.toDate().toISOString().split('T')[0];

//         // Retrieve fresh start dates matching the step date
//         const freshStartDates = freshStartMapping[user.uid] || [];
//         const matchingFreshStartDates = freshStartDates.filter(date => date === stepDate);

//         const row = {
//           SiNo: serialNumber++,
//           UserID: user.uid,
//           Group: user.group,
//           DayDate: stepDate,
//           DailySteps: step.Steps,
//           FreshStartDates: matchingFreshStartDates.join(', '),  // Display only matching fresh start dates
//         };

//         // Conditionally add fields based on permission
//         if (permission.includes("adduser")) {
//           row.Email = user.email;
//           row.FirstName = user.name.firstName;
//           row.LastName = user.name.secondName;
//         }

//         structuredData.push(row);
//       });
//     });

//     return structuredData;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw new Error('Failed to fetch data');
//   }
// };
