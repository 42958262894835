import React from 'react';
import Navbar from '../../components/Navbar';
import Login from '../views/Login';


function LandingPage() {
  return (
    <div className="w-full">
      <Navbar />
      <Login/>
     
    </div>
  );
}

export default LandingPage;
