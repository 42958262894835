import { Cookies } from 'react-cookie';

const cookies = new Cookies();


export const setCookie = (name, value) => {
  const expirationDate = new Date();

  expirationDate.setDate(expirationDate.getDate() + 30); 
  const  options = {  domain:process.env.REACT_APP_DOMAIN ,  expires: expirationDate , path:'/' };
  localStorage.setItem('user',JSON.stringify(value))
  cookies.set(name, value, options);
};

export const getCookie = (name) => {
  return cookies.get(name);
};

export const removeCookie = (name) => {
  const  options = {  domain:process.env.REACT_APP_DOMAIN,  path:'/' };
  cookies.remove(name,options);
};


