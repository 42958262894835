import React from 'react';
import Sidebar from '../../components/Sidebar';
import UserDetailsWrapper from '../views/UserDetails';

function UserDetailsLayout() {
  return (
    <div className="flex min-h-screen">
      <div className="md:w-64 sm:w-32 w-32 bg-white shadow-md z-10"> 
        <Sidebar />
      </div>
      <div className="flex-1 p-4 bg-gray-100 overflow-x-auto"> 
        <UserDetailsWrapper />
      </div>
    </div>
  );
}

export default UserDetailsLayout;
