// App.js
import React from "react";
import "./App.css";
import Index from "./routes/routes"; // Adjust the import to match your structure
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { CookiesProvider } from 'react-cookie';
import { UserProvider } from './services/authService'; // Import UserProvider

function App() {
  return (
    <CookiesProvider>
      <UserProvider>
        <Index />
        <ToastContainer />
      </UserProvider>
    </CookiesProvider>
  );
}

export default App;
