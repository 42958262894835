// Index.js
import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LandingPage from "../containers/layouts/LandingPage";
import LoginLayout from "../containers/layouts/LoginLayout";
import HomePageLayout from "../containers/layouts/HomePageLayout";
import UserDetailsLayout from "../containers/layouts/UserDetailsLayout";
import ForgotPasswordLayout from "../containers/layouts/ForgotPasswordLayout";
import AddAdminUserlayout from "../containers/layouts/AddAdminUserlayout";
import AddAppUserlayout from "../containers/layouts/AddAppUserLayout";
import MainLayout from "../containers/layouts/mainLayout";
import { useUser } from '../services/authService'; 
import { getCookie } from '../utils/cookieUtil';
import AddFacultylayout from "../containers/layouts/AddFacultylayout";

function AppRoutes() {
  const { user} = useUser(); 

  // useEffect(() => {
  //   const cookieUser = getCookie("user");
  //   if (cookieUser !== user) {
  //     setUser(cookieUser);
  //   }
  //   console.log("Router cookie user:", cookieUser);
  // }, [user, setUser]); // Add user and setUser to dependencies

  return (
    <Routes>
      <Route path="/login" element={user ? <Navigate to="/app" replace /> : <LoginLayout />} />
      <Route path="/" element={user ? <Navigate to="/app" replace /> : <LandingPage />} />
      <Route path="forgotpassword" element={<ForgotPasswordLayout />} />
      <Route path="/app" element={user ? <MainLayout /> : <Navigate to="/login" replace />}>
        <Route index element={<HomePageLayout />} />
        <Route path="userdetails" element={<UserDetailsLayout />} />
        <Route path="addadminuser" element={<AddAdminUserlayout />} />
        <Route path="addappuser" element={<AddAppUserlayout />} />
        <Route path="addfaculty" element={<AddFacultylayout/>} />
        <Route path="*" element={<Navigate to="/app" replace />} />
      </Route>
    </Routes>
  );
}

const Index = () => {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default Index;
