import React, { useState , useEffect } from "react";
import { createFaculty } from "../../services/authService";
import Header from "../../components/Header";
import axios from 'axios';


const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
    <div className="w-16 h-16 border-t-4 border-white border-solid rounded-full animate-spin"></div>
  </div>
);

const AddFaculty = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');
      setSuccess('');
    }, 2000);

    return () => clearTimeout(timer); 
  }, [error, success]);

  const generateTempPassword = () => {
    return Math.random().toString(36).slice(-8); 
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendEmail = async (email, tempPassword, newUser) => {
    const emailPayload = {
      email,
      subject: "Your Challenge App Temporary Admin Password",
      // content: newUser
      //   ? `Hello ${firstName},\n\nYour account has been created. Please use the following temporary password to log in:\n\nPassword: ${tempPassword}\n\nLink:https://dev.challengeapp.comptivia.com/`
      //   : `Hello ${firstName},\n\nYour account has been created. Please use the same password as the one used in the mobile app.\n\nLink:https://dev.challengeapp.comptivia.com/`,
      content: `
      <html>
        <body style="font-family: Arial, sans-serif; line-height: 1.6;">
          <div style="max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ddd; border-radius: 5px; background-color: #f9f9f9;">
            <h2 style="color: #333;">Welcome to Challenge App ${firstName}!</h2>
            <p style="color: #555;">
              Your account has been created successfully. Please find the details below:
            </p>
            ${newUser
              ? `
              <p style="color: #555;">
                Use the following temporary password to log in:
              </p>
              <p style="background-color: #f0f0f0; padding: 10px; border-radius: 5px; font-weight: bold;">
                <strong>Password:</strong> ${tempPassword}
              </p>
              <p style="color: #555;">
                For your security, please change this password after your first login.
              </p>
              `
              : `
              <p style="color: #555;">
                Please use the same password as the one used in the mobile app.
              </p>
              <p style="color: #555;">
                If you have forgotten your password, you can reset it through the app.
              </p>
              `}
            <p style="color: #555;">
              You can log in using the following link:
            </p>
            <p>
              <a href="https://dev.challengeapp.comptivia.com/" style="color: #007bff; text-decoration: none;">Log In</a>
            </p>
            <p style="color: #555;">
              If you have any questions, feel free to reach out to our support team.
            </p>
            <p style="color: #555;">Thank you for joining us!</p>
            <footer style="margin-top: 20px; font-size: 12px; color: #888;">
              <p>This email was sent to you by Challenge App.</p>
            </footer>
          </div>
        </body>
      </html>
    `,
    };

    const formData = new FormData();
    formData.append('email', emailPayload.email);
    formData.append('subject', emailPayload.subject);
    formData.append('content', emailPayload.content);

    try {
      const response = await axios.post(
        "https://dev-nginx-710048633533.asia-south1.run.app/org/Organization/sendmail",formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return { success: true };
    } catch (error) {
      console.error("Error sending email:", error);
      return { success: false, error: error.message };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);

    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    const tempPassword = generateTempPassword();

    try {
      const result = await createFaculty(
        email,
        tempPassword,
        firstName,
        lastName
      );
      if (result.success) {
        
        const emailResult = await sendEmail(
          email,
          tempPassword,
          result.newUser
        );

        if (emailResult.success) {
          setSuccess(
            "User created successfully, and an email with the temporary password was sent."
          );
        } else {
          setError(
            `User created, but email sending failed: ${emailResult.error}`
          );
        }

        // Reset form fields
        setEmail("");
        setFirstName("");
        setLastName("");
      } else {
        setError(`Error: ${result.message}`);
      }
    } catch (error) {
      setError(`Error: ${error.message}`);
    }finally {
      setLoading(false); 
    }
  };

  return (
    <div
      className="p-4 space-y-4"
      style={{ minHeight: "100vh", display: "flex", flexDirection: "column" }}
    >
      <Header />
      <div className="flex items-center justify-center w-full h-full">
        <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">
            Add New Faculty
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter user's email"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <input
                id="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter user's first name"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                id="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter user's last name"
                required
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-[#900028] text-white font-semibold rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Add Faculty
            </button>
            {error && <p className="text-red-500 text-center">{error}</p>}
            {success && <p className="text-green-500 text-center">{success}</p>}
          </form>
          {loading && <LoadingSpinner />}
        </div>
      </div>
    </div>
  );
};

export default AddFaculty;