import React, { useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { firestore } from '../../lib/firebase';
import Watermark from '../../assets/Watermark.png';
import Header from '../../components/Header';
import { getCookie } from "../../utils/cookieUtil";
import { EyeIcon, EyeOffIcon , InformationCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from 'react-tooltip';


const UserDetailsPage = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uid, setUid] = useState(null);
  const [permission, setPermission] = useState(''); 
  const [error, setError] = useState(null);
  // const [clickedIcon, setClickedIcon] = useState(null); // To track which icon is hovered
  
  // Modal content based on hovered icon
  const modalContent = {
    1: 'After completing another day of the challenge, how motivated are you to start the next day and try to reach your steps goal?',
    2: 'How do you rate your likelihood of reaching your steps goal today?',
    3: 'How committed do you think you are to reaching your steps goal in the next day?',
  };

  // const handleIconClick = (iconNumber) => {
  //   // Toggle the message visibility on click
  //   setClickedIcon(prevIcon => (prevIcon === iconNumber ? null : iconNumber));
  // };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const uidFromParams = params.get('uid');
    if (uidFromParams) {
      setUid(uidFromParams);
    }
  }, []);

  useEffect(() => {
    const roles= getCookie('roleId') 
    if (roles) {
      setPermission(`${roles} `);
    }
    
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!uid) return;
      setLoading(true);
      try {
        const userDoc = await getDoc(doc(firestore, 'Users', uid));
        const user = userDoc.exists() ? userDoc.data() : {};
        const balanceDoc = await getDoc(doc(firestore, 'Cumulative Balance', uid));
        const balance = balanceDoc.exists() ? balanceDoc.data().balance : 0;

        const dailyStepsQuery = query(collection(firestore, 'Daily Steps'), where('userId', '==', uid));
        const dailyStepsSnapshot = await getDocs(dailyStepsQuery);
        const dailySteps = dailyStepsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const feedbackQuery = query(collection(firestore, 'Review'), where('userId', '==', uid));
        const feedbackSnapshot = await getDocs(feedbackQuery);
        const feedback = feedbackSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        const freshStartSnapshot = await getDocs(collection(firestore, 'Fresh Start'));
        const freshStart = freshStartSnapshot.docs
          .map(doc => ({
            id: doc.id,
            Group: doc.data().Group,
            dates: doc.data().dates || [],
            freshStart: doc.data().freshStart
          }))
          .filter(doc => doc.id === uid);

        const allDates = freshStart.flatMap(item => item.dates);

        const goalsDoc = await getDoc(doc(firestore, 'Goals', uid));
        const goals = goalsDoc.exists() ? goalsDoc.data() : {};
        const goalDate = goals.goalSettingDate ? goals.goalSettingDate.toDate().toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }) : null;

        setUserDetails({ user, balance, dailySteps, freshStart, goals, goalDate, feedback, allDates });
      } catch (error) {
        console.error('Error fetching user details:', error);
        setError('Failed to fetch user details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [uid]);

  const LoadingSpinner = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="w-16 h-16 border-t-4 border-white border-solid rounded-full animate-spin"></div>
    </div>
  );

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="text-red-500">{error}</div>;

  const sortedDailySteps = userDetails?.dailySteps?.sort((a, b) => a.day - b.day) || [];
  const allDates = userDetails?.allDates || [];
  const sortedFeedback = userDetails?.feedback?.sort((a, b) => a.day - b.day) || [];

  return (
    <div className="flex flex-col min-h-screen bg-gray-100"  style={{
      backgroundImage: `url(${Watermark})`,
      backgroundSize: '50%',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}>
      <div className="flex flex-1 overflow-hidden ">
        <div className="flex-1 p-4 overflow-auto">
          <Header />

          <main className="flex-1 p-4 overflow-auto">
            <div className="flex flex-col sm:flex-row sm:flex-wrap gap-4 mb-6">
              <section className="flex-1 min-w-0 bg-transparent p-3 rounded-md border border-gray-300">
                <h2 className="text-lg font-semibold mb-3">User Details</h2>
                <div>
                {permission.includes("adduser") && (
                  <>
                  <p className="text-[0.5rem] sm:text-sm font-medium mb-1"><strong>First Name:</strong> {userDetails.user.name.firstName}</p>
                  <p className="text-[0.5rem] sm:text-sm font-medium mb-1"><strong>Second Name:</strong> {userDetails.user.name.secondName}</p>
                  <p className="text-[0.5rem] sm:text-sm font-medium mb-1"><strong>Email:</strong> {userDetails.user.email}</p>
                  </>
                )}
                  <p className="text-[0.5rem] sm:text-sm font-medium"><strong>UID:</strong> {uid}</p>
                  <p className="text-[0.5rem] sm:text-sm font-medium"><strong>Group:</strong> {userDetails.user.group}</p>
                </div>
              </section>

              {userDetails.goals && (
                <section className="flex-1 min-w-0 bg-[#FF96B3] p-3 rounded-md text-white shadow-sm">
                  <h2 className="text-lg font-semibold mb-3">Goal</h2>
                  <div>
                    <p className="text-[0.5rem] sm:text-sm font-medium mb-1"><strong>Goal:</strong> {userDetails.goals.Goal || 'N/A'}</p>
                    <p className="text-[0.5rem] sm:text-sm font-medium"><strong>Goal Setting Date:</strong> {userDetails.goalDate || 'N/A'}</p>
                  </div>
                </section>
              )}

{userDetails.balance !== undefined && (
  <section className="flex-1 min-w-0 bg-[#FF96B3] p-3 rounded-md text-white shadow-lg">
    <h2 className="text-lg font-semibold mb-3">Cumulative Balance</h2>
    <div>
      <p className="text-[0.5rem] sm:text-sm font-medium">
        <strong>Balance:</strong>{" "}
        {userDetails.balance > 0 ? `-${userDetails.balance}` : userDetails.balance < 0 ? `+${userDetails.balance}` : "0"}
      </p>
    </div>
  </section>
)}

            </div>

            <section className="mb-6">
              <h2 className="text-lg font-semibold mb-3">Fresh Start Dates</h2>
              <div className="relative">
                <div className="overflow-x-auto max-h-[200px]">
                  <table className="w-full text-xs text-left text-gray-500">
                    <thead className="text-xs text-black uppercase bg-[#F3E2E7] sticky top-0 z-10">
                      <tr>
                        <th className="px-3 py-2 text-black rounded-tl-lg">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allDates.length > 0 ? (
                        allDates.map((item, index) => (
                          <tr key={index} className="bg-white border-b">
                            <td className="px-3 text-black py-2">
                              {item.date ? (
                                item.date.toDate().toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric',
                                })
                              ) : (
                                'No Date Available'
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="px-3 py-2">No data</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <section className="mb-6">
              <h2 className="text-lg font-semibold mb-3">Daily Steps</h2>
              <div className="relative">
                <div className="overflow-x-auto max-h-[200px]">
                  <table className="w-full text-xs text-left text-gray-500">
                    <thead className="text-xs text-black uppercase bg-[#F3E2E7] sticky top-0 z-10">
                      <tr>
                        <th className="px-3 text-black py-2">Day</th>
                        <th className="px-3 text-black py-2">Steps</th>
                      </tr>
                    </thead>
                    <tbody>
                      {sortedDailySteps.map(step => (
                        <tr key={step.id} className="bg-white border-b">
                          <td className="px-3 text-black py-2">{step.day}</td>
                          <td className="px-3 text-black py-2">{step.Steps}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>

            <section>
      <h2 className="text-lg font-semibold mb-3">Feedback</h2>

      <div className="relative">
        <div className="overflow-x-auto max-h-[200px]">
          <table className="w-full text-xs text-left text-gray-500">
            <thead className="text-xs text-black uppercase bg-[#F3E2E7] sticky top-0 z-10">
              <tr>
                <th className="px-3 py-2 text-black rounded-tl-lg">Day</th>
                <th className="px-3 py-2 text-black">
                  <div className="inline-flex items-center justify-center">
                    <span>Question 1</span>
                    <InformationCircleIcon
                      data-tooltip-id="tooltip1" // Unique tooltip ID
                      data-tooltip-content={modalContent[1]} // Tooltip content
                      className="h-5 w-5 text-[#900028] ml-2"
                    />
                  </div>
                </th>
                <th className="px-3 py-2 text-black">
                  <div className="inline-flex items-center justify-center">
                    <span>Question 2</span>
                    <InformationCircleIcon
                      data-tooltip-id="tooltip2"
                      data-tooltip-content={modalContent[2]} // Tooltip content
                      className="h-5 w-5 text-[#900028] ml-2"
                    />
                  </div>
                </th>
                <th className="px-3 py-2 text-black rounded-tr-lg">
                  <div className="inline-flex items-center justify-center">
                    <span>Question 3</span>
                    <InformationCircleIcon
                      data-tooltip-id="tooltip3"
                      data-tooltip-content={modalContent[3]} // Tooltip content
                      className="h-5 w-5 text-[#900028] ml-2"
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedFeedback.length > 0 ? (
                sortedFeedback.map(review => (
                  <tr key={review.id} className="bg-white border-b">
                    <td className="px-3 text-black py-2">{review.day}</td>
                    <td className="px-3 text-black py-2">{review.question1}</td>
                    <td className="px-3 text-black py-2">{review.question2}</td>
                    <td className="px-3 text-black py-2">{review.question3}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="px-3 py-2 text-center">No feedback available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {/* Tooltip components for each question */}
      <Tooltip id="tooltip1" place="top" effect="solid" className="text-center" style={{ marginLeft: '170px' }} />
      <Tooltip id="tooltip2" place="top" effect="solid" className="text-center" />
      <Tooltip id="tooltip3" place="top" effect="solid" className="text-center" style={{ transform: 'translate(50px, -40px)' }} />
    </section>

          </main>
        </div>
      </div>
    </div>
  );
};

const UserDetailsWrapper = () => (
  <React.Suspense fallback={<div>Loading user details...</div>}>
    <UserDetailsPage />
  </React.Suspense>
);

export default UserDetailsWrapper;
