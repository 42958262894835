import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchIcon } from "@heroicons/react/solid";
import * as XLSX from "xlsx"; 
import { logoutUser, GET } from '../../services/authService';
import ExcelFilter from "../../components/ExcelFilter";
import Header from "../../components/Header";
import Watermark from '../../assets/Watermark.png';
import { getCookie } from "../../utils/cookieUtil";

export default function UserManagement() {
  const [users, setUsers] = useState([]);
  const [dataLoading, setDataLoading] = useState(true); 
  const [searchQuery, setSearchQuery] = useState(''); 
  const [permission, setPermission] = useState(''); 
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const itemsPerPage = 10; // Items per page
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchUsers = async () => {
      setDataLoading(true);
      try {
        const res = await GET(); 
        const data = await res.json();
        setUsers(data.users); 
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setDataLoading(false); 
      }
    };
    
    fetchUsers();
  }, []);

  useEffect(() => {
    const roles = getCookie('roleId');
    if (roles) {
      setPermission(`${roles} `);
    }
  }, []);

  const handleViewClick = (uid) => {
    navigate(`/app/userdetails?uid=${uid}`); 
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const filteredUsers = Array.isArray(users) ? users.filter(user =>
    user.email.toLowerCase().includes(searchQuery.toLowerCase())
  ) : [];

  // Calculate displayed users based on pagination
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentUsers = filteredUsers.slice(startIndex, startIndex + itemsPerPage);
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const goToPreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const goToNextPage = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  return (
    <div
      className="p-4 space-y-4"
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        backgroundImage: `url(${Watermark})`,
        backgroundSize: '45%',
        backgroundPosition: 'center 20px',
        backgroundRepeat: 'no-repeat'
      }}
    >
      {dataLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <div className="w-16 h-16 border-t-4 border-[#900028] border-solid rounded-full animate-spin"></div>
        </div>
      ) : (
        <>
          <Header />
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-medium">User List</h2>
            {permission.includes("adduser") && (
              <div className="flex items-center border border-gray-300 rounded-lg">
                <input
                  type="text"
                  placeholder="Search by email"
                  value={searchQuery}
                  onChange={handleSearchChange} 
                  className="px-4 py-2 text-black rounded-l-lg border-none focus:outline-none"
                />
                <button className="p-2">
                  <SearchIcon className="w-5 h-5 text-gray-500" />
                </button>
              </div>
            )}
          </div>

          <div className="flex-1 overflow-x-auto">
            <table className="w-full text-sm text-left border-collapse">
              <thead className="bg-[#FF96B3]">
                <tr>
                  {permission.includes("adduser") && (
                    <>
                      <th className="py-2 px-4 border-b border-gray-300 text-black-500">Email</th>
                      <th className="py-2 px-4 border-b border-gray-300">First Name</th>
                      <th className="py-2 px-4 border-b border-gray-300">Second Name</th>
                    </>
                  )}
                  <th className="py-2 px-4 border-b border-gray-300">Group</th>
                  <th className="py-2 px-4 border-b border-gray-300">UID</th>
                  <th className="py-2 px-4 border-b border-gray-300">Action</th>
                </tr>
              </thead>
              <tbody>
                {currentUsers.map(user => (
                  <tr key={user.uid} className="bg-[#F3E2E7] hover:bg-[#F1C4D8] text-gray transition duration-200">
                    {permission.includes("adduser") && (
                      <>
                        <td className="py-2 px-4 border-b border-gray-300">{user.email}</td>
                        <td className="py-2 px-4 border-b border-gray-300">{user.name.firstName}</td>
                        <td className="py-2 px-4 border-b border-gray-300">{user.name.secondName}</td>
                      </>
                    )}
                    <td className="py-2 px-4 border-b border-gray-300">{user.group}</td>
                    <td className="py-2 px-4 border-b border-gray-300">{user.uid}</td>
                    <td className="py-2 px-4 border-b border-gray-300">
                      <button 
                        onClick={() => handleViewClick(user.uid)} 
                        className="bg-indigo-600 text-white font-semibold py-1 px-3 rounded-md shadow hover:bg-indigo-700 transition duration-200"
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls - Moved above ExcelFilter */}
            <div className="flex justify-end items-center mt-4 mb-2 space-x-2">
  <button
    onClick={goToPreviousPage}
    disabled={currentPage === 1}
    className="px-3 py-2 bg-gray-300 rounded disabled:opacity-50"
  >
    &larr;
  </button>
  <span className="px-2">
    {`1-${Math.min(itemsPerPage, filteredUsers.length - startIndex)} of ${currentPage}`}
  </span>
  <button
    onClick={goToNextPage}
    disabled={currentPage === totalPages}
    className="px-3 py-2 bg-gray-300 rounded disabled:opacity-50"
  >
    &rarr;
  </button>
</div>



            {/* Export Button Below Pagination */}
            <ExcelFilter users={filteredUsers} />
          </div>
        </>
      )}
    </div>
  );
}
