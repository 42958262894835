import React, { useEffect, useState }  from 'react';
import logo from '../assets/Logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeftIcon , HomeIcon , UserIcon} from '@heroicons/react/solid';
import { getCookie } from "../utils/cookieUtil";

function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [permission, setPermission] = useState(''); 
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const roles= getCookie('roleId') 
    if (roles) {
      setPermission(`${roles} `);
    }
    
  }, []);

  const handleAddUserClick = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const ToAddAdminUser = () => {
    navigate('/app/addadminuser');
  };

  const ToAddAppUser = () => {
    navigate('/app/addappuser');
  };

  const ToAddFaculty = () => {
    navigate('/app/addfaculty');
  };

  const goToHome = () => {
    setIsDropdownOpen(false)
    navigate('/app');
  };

  const isAdminUserActive = location.pathname === '/app/addadminuser';
  const isAppUserActive = location.pathname === '/app/addappuser';
  const isFacultyActive = location.pathname === '/app/addfaculty';

  return (
    <aside className="fixed left-0 top-0 z-50 flex min-h-screen w-32 flex-col bg-gradient-to-b from-[#900028] to-[#6a002b] shadow-lg p-4 transition-all duration-200 ease-in-out sm:w-32 md:w-64">
      <div className="flex items-center justify-between mb-6">
        <button
          onClick={handleBack}
          className="flex items-center text-white font-semibold text-lg hover:underline"
        >
          <ArrowLeftIcon className="w-5 h-5 mr-2" />
          Back
        </button>
      </div>

      {/* Logo Section */}
      <div className="flex items-center justify-center py-4">
        <img src={logo} alt="Logo" className="w-20 h-auto sm:w-16" />
      </div>

      <div className="flex flex-col items-center mb-2">
  <button
    onClick={goToHome}
    className="bg-indigo-600 text-white w-20 sm:w-20 md:w-40 py-2 rounded-md shadow-md transition duration-200 ease-in-out transform hover:scale-105 flex items-center justify-center"
  >
    <HomeIcon className="w-7 h-7 mr-2 hidden sm:hidden md:block" /> {/* Home icon */}
    <h1 className='mr-6'>Home</h1>
  </button>
</div>


      {/* Add User Button */}
      <div className="flex flex-col items-center">

      {permission.includes("adduser") && (
      <button
  onClick={handleAddUserClick}
  className="bg-indigo-600 text-white w-20 sm:w-20 md:w-40 py-2 rounded-md shadow-md transition duration-200 ease-in-out transform hover:scale-105 mb-2 flex items-center justify-center"
>
  <UserIcon className="w-8 h-8 mr-2 hidden sm:hidden md:block" /> {/* User icon */}
  Add User {/* Text next to the icon */}
</button>
      )}
        {/* Dropdown Buttons */}
        {isDropdownOpen && (
          <div className="flex flex-col items-center mt-2 space-y-2">
            <button
              onClick={ToAddAdminUser}
              className={`w-20 sm:w-20 md:w-40   text-[0.7rem]  py-2 rounded-md shadow-md transition duration-200 ease-in-out transform ${
                isAdminUserActive ? 'bg-[#B7C4CF] text-black scale-105' : 'bg-white text-black'
              }`}
            >
              Add Admin User
            </button>
            <button
              onClick={ToAddAppUser}
              className={`w-20 sm:w-20 md:w-40 text-[0.7rem] py-2 rounded-md shadow-md transition duration-200 ease-in-out transform ${
                isAppUserActive ? 'bg-[#B7C4CF] text-black scale-105' : 'bg-white text-black'
              }`}
            >
              Add App User
            </button>
            <button
              onClick={ToAddFaculty}
              className={`w-20 sm:w-20 md:w-40 text-[0.7rem] py-2 rounded-md shadow-md transition duration-200 ease-in-out transform ${
                isFacultyActive ? 'bg-[#B7C4CF] text-black scale-105' : 'bg-white text-black'
              }`}
            >
              Add Faculty
            </button>
          </div>
        )}
      </div>
    </aside>
  );
}

export default Sidebar;
