import React, { useState } from 'react';
import Modal from 'react-modal';
import * as XLSX from 'xlsx';
import { ExcelExport } from '../services/exportData';

Modal.setAppElement('#root');

const ExcelFilter = ({ users }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [startDay, setStartDay] = useState('');
  const [endDay, setEndDay] = useState('');
  const [dataLoading, setDataLoading] = useState(false);
  const [excelStarted,exportExcelStarted]=useState(false);


  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const exportToExcel = async () => {
    setDataLoading(true);

    try {
      
      const data = await ExcelExport(startDay, endDay); 
      console.log('Fetched data:', data); 

      if (data && Array.isArray(data)) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'User Data');

        XLSX.writeFile(workbook, 'User_Data.xlsx');
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error fetching data for export:', error);
    } finally {
      setDataLoading(false);
      closeModal(); 
    }
  };


  const exportToExcelAll = async () => {
    exportExcelStarted(true);
    try {
      const data = await ExcelExport('',''); 
      console.log('Fetched data:', data); 

      if (data && Array.isArray(data)) {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'User Data');

        XLSX.writeFile(workbook, 'User_Data.xlsx');
      } else {
        console.error('Unexpected data format:', data);
      }
    } catch (error) {
      console.error('Error fetching data for export:', error);
    } finally {
      exportExcelStarted(false);
      closeModal(); 
    }
  };

  return (
    <div>
      <button 
        onClick={openModal} 
        className="mt-4 px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-200"
      >
        {dataLoading ? 'Exporting...' : 'Export '}
      </button>

      <button 
            onClick={exportToExcelAll} 
            className="px-4 py-2 ml-3 bg-red-600 text-white rounded-md hover:bg-indigo-700 transition duration-200"
            disabled={dataLoading} 
          >
            {excelStarted ? 'Exporting...' : 'Export All'}
          </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Filter Modal"
        className="modal-class w-full max-w-md mx-auto bg-white rounded-lg shadow-lg p-6"
        overlayClassName="modal-overlay-class fixed inset-0 bg-black bg-opacity-50"
      >
        <h2 className="text-xl font-semibold mb-4">Select Days</h2>
        
        <div className="mb-4">
          <label className="block mb-1">Start Day:</label>
          <input 
            type="number" 
            min="1" 
            max="30" 
            value={startDay} 
            onChange={(e) => setStartDay(e.target.value)} 
            className="p-2 border border-gray-300 rounded-md w-full"
          />
        </div>

        <div className="mb-4">
          <label className="block mb-1">End Day:</label>
          <input 
            type="number" 
            min="1" 
            max="30" 
            value={endDay} 
            onChange={(e) => setEndDay(e.target.value)} 
            className="p-2 border border-gray-300 rounded-md w-full"
          />
        </div>


        <div className="flex justify-end">
          <button 
            onClick={closeModal} 
            className="mr-2 px-4 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 transition duration-200"
          >
            Cancel
          </button>
          <button 
            onClick={exportToExcel} 
            className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition duration-200"
            disabled={dataLoading} // Disable while loading
          >
            {dataLoading ? 'Exporting...' : 'Export'}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ExcelFilter;