import React from 'react';
import NavbarLogin from '../../components/NavbarLogin';
import walkingImg from '../../assets/walking.png';

function LandingPage() {
  return (
    <div className="w-full">
      <NavbarLogin />
      
      <div className="relative w-full h-[calc(90vh-100px)]">
        <img
          src={walkingImg}
          alt="Walking Image"
          className="absolute inset-0 w-full h-full object-contain z-0" // Ensures the image fills the container
        />
      </div>
    </div>
  );
}

export default LandingPage;
