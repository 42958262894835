import React, { useEffect, useState } from "react";
import { useUser, GET } from '../services/authService';
import { useNavigate } from "react-router-dom";
import { getCookie } from "../utils/cookieUtil";

function Header() {

    const [showLogout, setShowLogout] = useState(false);
    const [userName, setUserName] = useState(''); 
    const navigate = useNavigate(); 
    const { logoutUser } = useUser();

    const handleInitialsClick = () => {
        setShowLogout((prev) => !prev); 
      };
    

      useEffect(() => {
        // const firstName = getCookie('firstName');
        // const lastName = getCookie('lastName');
        const user= getCookie('user')
        const roles= getCookie('roleId')
        // console.log(">>>>>>>>>>>>>>>>>>>>",roles);
        
        if (user) {
          setUserName(`${user.firstName} ${user.lastName}`);
        }
        
      }, []);


      const handleLogout = async () => {
        try {

          console.log("clickedd logouuuttt");
          
          await logoutUser();

          console.log("after logoutttt");
          
          navigate('/login'); 
        } catch (error) {
          console.error('Error logging out:', error);
        }
      };


  return (
    <div className="flex justify-between items-center mb-4">
            <h1 className="text-xl font-bold">User Management</h1>
            <div className="relative flex items-center gap-3">
            <div className="text-sm font-bold uppercase">{userName}</div>
              <div
                className="w-10 h-10 rounded-full bg-gray-300 flex items-center justify-center text-white cursor-pointer"
                onClick={handleInitialsClick}
              >
        
                <span className="text-xl">{userName.split(' ').map(name => name[0]).join('')}</span>
              </div>

              {showLogout && (
                <div className="absolute top-12 right-0 mt-2 bg-white border rounded shadow-lg p-2">
                  <button className="w-full text-red-500 hover:text-red-700" onClick={handleLogout}>
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
  )
}

export default Header
