import React, { useState, useEffect } from 'react'; 
import { Link, useNavigate } from 'react-router-dom'; 
import walkingImg from '../../assets/walking.png';
import logochallenge from '../../assets/logoChallenge.png'; 
import { loginUser } from '../../services/authService';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');
    }, 3000);

    return () => clearTimeout(timer); 
  }, [error]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      return;
    }
    
    if (!password) {
      setError('Password cannot be empty.');
      return;
    }

    setLoading(true); // Start loading spinner
    try {
      await loginUser(email, password, navigate);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const forgotPassword = () => {
    try {
      navigate('/forgotpassword');
    } catch (error) {
      setError(error.message);
    }
  };

  // Loading spinner component
  const LoadingSpinner = () => (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <div className="w-16 h-16 border-t-4 border-white border-solid rounded-full animate-spin"></div>
    </div>
  );

  return (
    <div className="w-full">
      {loading && <LoadingSpinner />} {/* Display the spinner if loading */}
      <div className="flex-grow flex">
        <div className="relative w-1/2 h-[calc(100vh-100px)]"> 
          <img
            src={walkingImg}
            alt="WalkingImage"
            className="absolute inset-0 w-full h-full object-cover z-0" 
          />
        </div>

        {/* Login Form Section */}
        <div className="relative w-1/2 h-[calc(100vh-100px)] flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg w-full max-w-sm">
            <div className="flex justify-center mb-6">
              <Link to="/">
                <img
                  src={logochallenge}
                  alt="Challenge Logo"
                  className="w-28 lg:w-40" 
                />
              </Link>
            </div>
            <form onSubmit={handleSubmit}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 mb-4 border border-gray-300 rounded-md focus:outline-none"
                style={{
                  boxShadow: '0 4px 6px rgba(144, 0, 40, 0.1), 0 1px 3px rgba(144, 0, 40, 0.08), 0 0 8px rgba(144, 0, 40, 0.5)'
                }}
              />

              <div className="relative mb-4">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-3 border border-gray-300 rounded-md focus:outline-none"
                  style={{
                    boxShadow: '0 4px 6px rgba(144, 0, 40, 0.1), 0 1px 3px rgba(144, 0, 40, 0.08), 0 0 8px rgba(144, 0, 40, 0.5)'
                  }}
                />
                <button
                  type="button"
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeOffIcon className="h-5 w-5 text-[#900028]" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-[#900028]" />
                  )}
                </button>
              </div>
              
              <button
                type="submit"
                className="w-full py-3 bg-[#900028] text-white rounded-md hover:bg-[#750021] focus:outline-none focus:ring-2 focus:ring-[#900028]"
                disabled={loading}
              >
                Login
              </button>
              {error && <p className="text-red-500 mt-4">{error}</p>}
              <div className="flex justify-between text-sm mt-4">
                <Link to="/" className="text-grey hover:underline">
                  Back
                </Link>
                <button type="button" onClick={forgotPassword} className="text-[#900028] hover:underline">
                  Forgot Password?
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
