// import React, { useState, useEffect } from 'react';
// import { createAppUser } from '../../services/authService';
// import Header from '../../components/Header';
// import axios from 'axios';
// import Papa from 'papaparse';

// const LoadingSpinner = () => (
//   <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
//     <div className="w-16 h-16 border-t-4 border-white border-solid rounded-full animate-spin"></div>
//   </div>
// );

// const AddAppUser = () => {
//   const [email, setEmail] = useState('');
//   const [firstName, setFirstName] = useState('');
//   const [lastName, setLastName] = useState('');
//   const [group, setGroup] = useState('');
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setError('');
//       setSuccess('');
//     }, 2000);

//     return () => clearTimeout(timer);
//   }, [error, success]);

//   const isValidEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//     const sendEmail = async (email,newUser) => {
//     const emailPayload = {
//       email,
//       subject: "Your Challenge App Temporary Admin Password",
//       content: `
//       <html>
//     <body style="font-family: Arial, sans-serif; line-height: 1.6;">
//     <div style="max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ddd; border-radius: 5px; background-color: #f9f9f9;">
//     <h2 style="color: #333;">Welcome to Challenge App, ${firstName}!</h2>
      
//       ${newUser
//         ? `
//         <p style="color: #555;">
//   You have been selected to participate in the Fordham 30-Day Challenge!
// </p>
// <p style="color: #555;">
//   To get started, please install the <em>Fordham Challenge</em> app from your app store. Once installed, you'll be ready to dive in and begin the challenge.
// </p>
// <p style="color: #555;">
//   Thank you for joining us—let’s make these next 30 days impactful!
// </p>

//         `
//         : `
//         <p style="color: #555;">
//           Welcome to Challenge App! You can now continue using the app with the same password you use for the Challenge App Admin Portal.
//         </p>
//         `}
        
//       <p style="color: #555;">
//         If you need assistance, please reach out to our support team at any time.
//       </p>
//       <p style="color: #555;">Thank you for being part of Challenge App!</p>
//       <footer style="margin-top: 20px; font-size: 12px; color: #888;">
//         <p>This email was sent to you by Challenge App.</p>
//       </footer>
//     </div>
//   </body>
// </html>

//     `,
//     };

//     const formData = new FormData();
//     formData.append('email', emailPayload.email);
//     formData.append('subject', emailPayload.subject);
//     formData.append('content', emailPayload.content);

    

//     try {
//       const response = await axios.post(
//         "https://dev-nginx-710048633533.asia-south1.run.app/org/Organization/sendmail",formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       return { success: true };
//     } catch (error) {
//       console.error("Error sending email:", error);
//       return { success: false, error: error.message };
//     }
//   };



//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError('');
//     setSuccess('');
//     setLoading(true);

//     if (!['A', 'B', 'C'].includes(group.toUpperCase())) {
//       setError('Group must be A, B, or C.');
//       setLoading(false);
//       return;
//     }

//     if (!isValidEmail(email)) {
//       setError('Please enter a valid email address.');
//       setLoading(false);
//       return;
//     }

//     try {
//       const result = await createAppUser(email, firstName, lastName, group);
//       if (result.success) {
//         const emailResult = await sendEmail(email, result.newUser);
//         if (emailResult.success) {
//           setSuccess('User created successfully and email sent.');
//         } else {
//           setError(`User created, but email sending failed: ${emailResult.error}`);
//         }
//         setEmail('');
//         setFirstName('');
//         setLastName('');
//         setGroup('');
//       } else {
//         setError(`Error: ${result.message}`);
//       }
//     } catch (error) {
//       setError(`Error: ${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     if (!file) return;
  
//     setLoading(true);
//     Papa.parse(file, {
//       header: true,
//       skipEmptyLines: true,  // Skip completely empty rows
//       complete: async (results) => {
//         const users = results.data.filter(user => user.Email && user.FirstName && user.LastName && user.Group); // Filter out rows with missing data
  
//         for (let i = 0; i < users.length; i++) {
//           const user = users[i];
  
//           const email = user.Email?.trim();
//           const firstName = user.FirstName?.trim();
//           const lastName = user.LastName?.trim();
//           const group = user.Group?.trim().toUpperCase();
  
//           // Check email and group validity
//           if (!isValidEmail(email)) {
//             setError(`Invalid email format for user on row ${i + 1}: ${email}`);
//             continue;
//           }
//           if (!['A', 'B', 'C'].includes(group)) {
//             setError(`Invalid group for user on row ${i + 1}: ${group}. Must be A, B, or C.`);
//             continue;
//           }
  
//           try {
//             const result = await createAppUser(email, firstName, lastName, group);
//             if (result.success) {
//               await sendEmail(email, result.newUser);
//             } else {
//               setError(`Error creating user ${email} on row ${i + 1}: ${result.message}`);
//             }
//           } catch (error) {
//             setError(`Error creating user ${email} on row ${i + 1}: ${error.message}`);
//           }
//         }
//         setSuccess('Bulk user creation completed.');
//         setLoading(false);
//       },
//       error: (error) => {
//         setError(`File parsing error: ${error.message}`);
//         setLoading(false);
//       },
//     });
//   };
  

//   return (
//     <div className="p-4 space-y-4" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
//       <Header />
//       <div className="flex items-center justify-center w-full h-full">
//         <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
//           <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">Add New App User</h2>
//           <form onSubmit={handleSubmit} className="space-y-4">
//             <div>
//               <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
//               <input
//                 id="email"
//                 type="email"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 placeholder="Enter user's email"
//                 required
//                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//               />
//             </div>
//             <div>
//               <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
//               <input
//                 id="firstName"
//                 type="text"
//                 value={firstName}
//                 onChange={(e) => setFirstName(e.target.value)}
//                 placeholder="Enter user's first name"
//                 required
//                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//               />
//             </div>
//             <div>
//               <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
//               <input
//                 id="lastName"
//                 type="text"
//                 value={lastName}
//                 onChange={(e) => setLastName(e.target.value)}
//                 placeholder="Enter user's last name"
//                 required
//                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//               />
//             </div>
//             <div>
//               <label htmlFor="group" className="block text-sm font-medium text-gray-700">Group (A, B, C)</label>
//               <input
//                 id="group"
//                 type="text"
//                 value={group}
//                 onChange={(e) => setGroup(e.target.value.toUpperCase())}
//                 placeholder="Enter group (A, B, or C)"
//                 required
//                 maxLength="1"
//                 className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//               />
//             </div>
//             <button
//               type="submit"
//               className="w-full py-2 px-4 bg-[#900028] text-white font-semibold rounded-md shadow-sm"
//             >
//               Add User
//             </button>
//             {error && <p className="text-red-500 text-center">{error}</p>}
//             {success && <p className="text-green-500 text-center">{success}</p>}
//           </form>
//           <div className="mt-4">
//             <label htmlFor="fileUpload" className="block text-sm font-medium text-gray-700">Upload CSV for Bulk User Creation</label>
//             <input
//               id="fileUpload"
//               type="file"
//               accept=".csv"
//               onChange={handleFileUpload}
//               className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//             />
//           </div>
//           {loading && <LoadingSpinner />}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AddAppUser;

// import React, { useState, useEffect } from 'react';
// import { createAppUser } from '../../services/authService';
// import Header from '../../components/Header';
// import axios from 'axios';
// import * as XLSX from 'xlsx';

// const LoadingSpinner = () => (
//   <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
//     <div className="w-16 h-16 border-t-4 border-white border-solid rounded-full animate-spin"></div>
//   </div>
// );

// const AddAppUser = () => {
//   const [email, setEmail] = useState('');
//   const [firstName, setFirstName] = useState('');
//   const [lastName, setLastName] = useState('');
//   const [group, setGroup] = useState('');
//   const [error, setError] = useState('');
//   const [success, setSuccess] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [file, setFile] = useState(null);
//   const [isBulkUpload, setIsBulkUpload] = useState(false);

//   useEffect(() => {
//     const timer = setTimeout(() => {
//       setError('');
//       setSuccess('');
//     }, 10000);
//     return () => clearTimeout(timer);
//   }, [error, success]);

//   const isValidEmail = (email) => {
//     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return emailRegex.test(email);
//   };

//     const sendEmail = async (email,newUser) => {
//     const emailPayload = {
//       email,
//       subject: "Your Challenge App Temporary Admin Password",
//       content: `
//       <html>
//     <body style="font-family: Arial, sans-serif; line-height: 1.6;">
//     <div style="max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ddd; border-radius: 5px; background-color: #f9f9f9;">
//     <h2 style="color: #333;">Welcome to Challenge App, ${firstName}!</h2>
      
//       ${newUser
//         ? `
//         <p style="color: #555;">
//   You have been selected to participate in the Fordham 30-Day Challenge!
// </p>
// <p style="color: #555;">
//   To get started, please install the <em>Fordham Challenge</em> app from your app store. Once installed, you'll be ready to dive in and begin the challenge.
// </p>
// <p style="color: #555;">
//   Thank you for joining us—let’s make these next 30 days impactful!
// </p>

//         `
//         : `
//         <p style="color: #555;">
//           Welcome to Challenge App! You can now continue using the app with the same password you use for the Challenge App Admin Portal.
//         </p>
//         `}
        
//       <p style="color: #555;">
//         If you need assistance, please reach out to our support team at any time.
//       </p>
//       <p style="color: #555;">Thank you for being part of Challenge App!</p>
//       <footer style="margin-top: 20px; font-size: 12px; color: #888;">
//         <p>This email was sent to you by Challenge App.</p>
//       </footer>
//     </div>
//   </body>
// </html>

//     `,
//     };

//     const formData = new FormData();
//     formData.append('email', emailPayload.email);
//     formData.append('subject', emailPayload.subject);
//     formData.append('content', emailPayload.content);

    

//     try {
//       const response = await axios.post(
//         "https://dev-nginx-710048633533.asia-south1.run.app/org/Organization/sendmail",formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       return { success: true };
//     } catch (error) {
//       console.error("Error sending email:", error);
//       return { success: false, error: error.message };
//     }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError('');
//     setSuccess('');
//     setLoading(true);

//     if (!['A', 'B', 'C'].includes(group.toUpperCase())) {
//       setError('Group must be A, B, or C.');
//       setLoading(false);
//       return;
//     }

//     if (!isValidEmail(email)) {
//       setError('Please enter a valid email address.');
//       setLoading(false);
//       return;
//     }

//     try {
//       const result = await createAppUser(email, firstName, lastName, group);
//       if (result.success) {
//         const emailResult = await sendEmail(email, result.newUser);
//         if (emailResult.success) {
//           setSuccess('User created successfully and email sent.');
//         } else {
//           setError(`User created, but email sending failed: ${emailResult.error}`);
//         }
//         setEmail('');
//         setFirstName('');
//         setLastName('');
//         setGroup('');
//       } else {
//         setError(`Error: ${result.message}`);
//       }
//     } catch (error) {
//       setError(`Error: ${error.message}`);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleFileChange = (event) => {
//     setFile(event.target.files[0]);
//   };

//   const handleUpload = () => {
//     if (!file) {
//       setError("Please select a file before uploading.");
//       return;
//     }

//     setLoading(true);
//     const reader = new FileReader();
//     reader.onload = async (e) => {
//       const data = new Uint8Array(e.target.result);
//       const workbook = XLSX.read(data, { type: 'array' });
//       const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
//       const users = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

//       const headers = users[0];
//       const userData = users.slice(1);

//       for (let i = 0; i < userData.length; i++) {
//         const user = userData[i];
//         const email = user[headers.indexOf('Email')]?.trim();
//         const firstName = user[headers.indexOf('First Name')]?.trim();
//         const lastName = user[headers.indexOf('Last Name')]?.trim();
//         const group = user[headers.indexOf('Group')]?.trim().toUpperCase();

//         if (!isValidEmail(email)) {
//           setError(`Invalid email format for user on row ${i + 2}: ${email}`);
//           continue;
//         }
//         if (!['A', 'B', 'C'].includes(group)) {
//           setError(`Invalid group for user on row ${i + 2}: ${group}. Must be A, B, or C.`);
//           continue;
//         }

//         try {
//           const result = await createAppUser(email, firstName, lastName, group);
//           if (result.success) {
//             await sendEmail(email, result.newUser);
//           } else {
//             setError(`Error creating user ${email} on row ${i + 2}: ${result.message}`);
//           }
//         } catch (error) {
//           setError(`Error creating user ${email} on row ${i + 2}: ${error.message}`);
//         }
//       }
//       setSuccess('Bulk user creation completed.');
//       setLoading(false);
//       setFile(null);
//     };

//     reader.onerror = (error) => {
//       setError(`File reading error: ${error.message}`);
//       setLoading(false);
//       setFile(null);
//     };

//     reader.readAsArrayBuffer(file);
//   };

//   const downloadTemplate = () => {
//     const wb = XLSX.utils.book_new();
//     const ws = XLSX.utils.aoa_to_sheet([
//       ['Email', 'First Name', 'Last Name', 'Group'],
//     ]);
//     XLSX.utils.book_append_sheet(wb, ws, 'User Template');
//     XLSX.writeFile(wb, 'User_Template.xlsx');
//   };

//   const handleBulkUploadClick = () => {
//     setIsBulkUpload(true);
//   };

//   return (
//     <div className="p-4 space-y-4" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
//       <Header />
//       <div className="flex items-center justify-center w-full h-full">
//         <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
//           <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">Add New App User</h2>
          
//           <div className="flex justify-center mb-6">
//             <button
//               onClick={() => setIsBulkUpload(false)}
//               className={`px-4 py-2 ${!isBulkUpload ? "bg-indigo-500 text-white" : "bg-gray-200 text-gray-700"} rounded-l-md`}
//             >
//               Single User
//             </button>
//             <button
//               onClick={handleBulkUploadClick}
//               className={`px-4 py-2 ${isBulkUpload ? "bg-indigo-500 text-white" : "bg-gray-200 text-gray-700"} rounded-r-md`}
//             >
//               Bulk Upload
//             </button>
//           </div>

//           {!isBulkUpload ? (
//             <form onSubmit={handleSubmit} className="space-y-4">
//               <div>
//                 <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
//                 <input
//                   id="email"
//                   type="email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   placeholder="Enter user's email"
//                   required
//                   className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//                 />
//               </div>
//               <div>
//                 <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
//                 <input
//                   id="firstName"
//                   type="text"
//                   value={firstName}
//                   onChange={(e) => setFirstName(e.target.value)}
//                   placeholder="Enter user's first name"
//                   required
//                   className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//                 />
//               </div>
//               <div>
//                 <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
//                 <input
//                   id="lastName"
//                   type="text"
//                   value={lastName}
//                   onChange={(e) => setLastName(e.target.value)}
//                   placeholder="Enter user's last name"
//                   required
//                   className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//                 />
//               </div>
//               <div>
//                 <label htmlFor="group" className="block text-sm font-medium text-gray-700">Group (A, B, or C)</label>
//                 <input
//                   id="group"
//                   type="text"
//                   value={group}
//                   onChange={(e) => setGroup(e.target.value)}
//                   placeholder="Enter user's group"
//                   required
//                   className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
//                 />
//               </div>
//               <button type="submit" className="w-full bg-indigo-500 text-white py-2 px-4 rounded-md shadow-sm">
//                 Add User
//               </button>
//             </form>
//           ) : (
//             <div className="space-y-4">
//               <input type="file" onChange={handleFileChange} />
//               <button
//                 onClick={handleUpload}
//                 className="w-full bg-indigo-500 text-white py-2 px-4 rounded-md shadow-sm"
//               >
//                 Upload File
//               </button>
//               <button
//   onClick={downloadTemplate}
//   className="mt-2 bg-black text-gray-100 py-1 px-1.5 rounded-md shadow-sm text-sm"
// >
//   Download Template
// </button>

//             </div>
//           )}

//           {error && <p className="mt-4 text-center text-red-500">{error}</p>}
//           {success && <p className="mt-4 text-center text-green-500">{success}</p>}
//         </div>
//       </div>

//       {loading && <LoadingSpinner />}
//     </div>
//   );
// };

// export default AddAppUser;


import React, { useState, useEffect } from 'react';
import { createAppUser } from '../../services/authService';
import Header from '../../components/Header';
import axios from 'axios';
import * as XLSX from 'xlsx';

const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
    <div className="w-16 h-16 border-t-4 border-white border-solid rounded-full animate-spin"></div>
  </div>
);

const AddAppUser = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [group, setGroup] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [invalidEmails, setInvalidEmails] = useState([]); // Step 1: Track invalid emails

  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');
      setSuccess('');
    }, 10000);
    return () => clearTimeout(timer);
  }, [error, success]);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const sendEmail = async (email, newUser) => {
    const emailPayload = {
      email,
      subject: "Your Challenge App Temporary Admin Password",
      content: `
      <html>
    <body style="font-family: Arial, sans-serif; line-height: 1.6;">
    <div style="max-width: 600px; margin: auto; padding: 20px; border: 1px solid #ddd; border-radius: 5px; background-color: #f9f9f9;">
    <h2 style="color: #333;">Welcome to Challenge App ${firstName}!</h2>
      
      ${newUser
        ? `
        <p style="color: #555;">
  You have been selected to participate in the Fordham 30-Day Challenge!
</p>
<p style="color: #555;">
  To get started, please install the <em>Fordham Challenge</em> app from your app store. Once installed, you'll be ready to dive in and begin the challenge.
</p>
<p style="color: #555;">
  Thank you for joining us—let’s make these next 30 days impactful!
</p>
        `
        : `
        <p style="color: #555;">
          Welcome to Challenge App! You can now continue using the app with the same password you use for the Challenge App Admin Portal.
        </p>
        `}
        
      <p style="color: #555;">
        If you need assistance, please reach out to our support team at any time.
      </p>
      <p style="color: #555;">Thank you for being part of Challenge App!</p>
      <footer style="margin-top: 20px; font-size: 12px; color: #888;">
        <p>This email was sent to you by Challenge App.</p>
      </footer>
    </div>
  </body>
</html>
    `,
    };

    const formData = new FormData();
    formData.append('email', emailPayload.email);
    formData.append('subject', emailPayload.subject);
    formData.append('content', emailPayload.content);

    try {
      const response = await axios.post(
        "https://dev-nginx-710048633533.asia-south1.run.app/org/Organization/sendmail",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      return { success: true };
    } catch (error) {
      console.error("Error sending email:", error);
      return { success: false, error: error.message };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    if (!['A', 'B', 'C'].includes(group.toUpperCase())) {
      setError('Group must be A, B, or C.');
      setLoading(false);
      return;
    }

    if (!isValidEmail(email)) {
      setError('Please enter a valid email address.');
      setLoading(false);
      return;
    }

    try {
      const result = await createAppUser(email, firstName, lastName, group);
      if (result.success) {
        const emailResult = await sendEmail(email, result.newUser);
        if (emailResult.success) {
          setSuccess('User created successfully and email sent.');
        } else {
          setError(`User created, but email sending failed: ${emailResult.error}`);
        }
        setEmail('');
        setFirstName('');
        setLastName('');
        setGroup('');
      } else {
        setError(`Error: ${result.message}`);
      }
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
};

const handleUpload = () => {
    if (!file) {
        setError("Please select a file before uploading.");
        return;
    }

    // Step 1: Validate file type
    const validMimeTypes = [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel"
    ];
    const validExtensions = ["xlsx", "xls"];

    const fileExtension = file.name.split('.').pop().toLowerCase();
    if (!validMimeTypes.includes(file.type) || !validExtensions.includes(fileExtension)) {
        setError("Invalid file type. Please upload an Excel file (.xlsx or .xls).");
        return;
    }

    setLoading(true);
    const reader = new FileReader();
    reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const users = XLSX.utils.sheet_to_json(firstSheet, { header: 1 });

        const headers = users[0];
        const userData = users.slice(1);
        const newInvalidEmails = [];

        for (let i = 0; i < userData.length; i++) {
            const user = userData[i];
            const email = user[headers.indexOf('Email')]?.trim();
            const firstName = user[headers.indexOf('First Name')]?.trim();
            const lastName = user[headers.indexOf('Last Name')]?.trim();
            const group = user[headers.indexOf('Group')]?.trim().toUpperCase();

            if (!email || !isValidEmail(email)) {
              newInvalidEmails.push({ email: email || "N/A", error: `Invalid or missing email format on row ${i + 2}.` });
              continue;
          }
          if (!firstName) {
              newInvalidEmails.push({ email, error: `Missing first name on row ${i + 2}.` });
              continue;
          }
          if (!lastName) {
              newInvalidEmails.push({ email, error: `Missing last name on row ${i + 2}.` });
              continue;
          }
            if (!['A', 'B', 'C'].includes(group)) {
                newInvalidEmails.push({ email, error: `Invalid group on row ${i + 2}. Must be A, B, or C.` });
                continue;
            }

            try {
                const result = await createAppUser(email, firstName, lastName, group);
                if (result.success) {
                    await sendEmail(email, result.newUser);
                } else {
                    newInvalidEmails.push({ email, error: `Error creating user: ${result.message}` });
                }
            } catch (error) {
                newInvalidEmails.push({ email, error: `Error creating user: ${error.message}` });
            }
        }

        setInvalidEmails(newInvalidEmails);
        setSuccess('Bulk user creation completed.');
        setLoading(false);
        setFile(null);
    };

    reader.onerror = (error) => {
        setError(`File reading error: ${error.message}`);
        setLoading(false);
        setFile(null);
    };

    reader.readAsArrayBuffer(file);
};


  const downloadTemplate = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      ['Email', 'First Name', 'Last Name', 'Group'],
    ]);
    XLSX.utils.book_append_sheet(wb, ws, 'User Template');
    XLSX.writeFile(wb, 'User_Template.xlsx');
  };

  const handleBulkUploadClick = () => {
    setIsBulkUpload(true);
  };

  return (
    <div className="p-4 space-y-4" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <div className="flex items-center justify-center w-full h-full">
        <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-6 text-center text-gray-800">Add New App User</h2>
          
          <div className="flex justify-center mb-6">
            <button
              onClick={() => setIsBulkUpload(false)}
              className={`px-4 py-2 ${!isBulkUpload ? "bg-[#900028] text-white" : "bg-gray-200 text-gray-700"} rounded-l-md`}
            >
              Single User
            </button>
            <button
              onClick={handleBulkUploadClick}
              className={`px-4 py-2 ${isBulkUpload ? "bg-[#900028] text-white" : "bg-gray-200 text-gray-700"} rounded-r-md`}
            >
              Bulk Upload
            </button>
          </div>

          {!isBulkUpload ? (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-gray-700">Email:</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div>
                <label className="block text-gray-700">First Name:</label>
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                  className="w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div>
                <label className="block text-gray-700">Last Name:</label>
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                  className="w-full border border-gray-300 rounded-md p-2"
                />
              </div>
              <div>
                <label className="block text-gray-700">Group:</label>
                <select
                  value={group}
                  onChange={(e) => setGroup(e.target.value)}
                  required
                  className="w-full border border-gray-300 rounded-md p-2"
                >
                  <option value="">Select a group</option>
                  <option value="A">A</option>
                  <option value="B">B</option>
                  <option value="C">C</option>
                </select>
              </div>
              <button
                type="submit"
                className="w-full bg-[#900028] text-white py-2 px-4 rounded-md shadow-sm"
              >
                Add AppUser
              </button>
            </form>
          ) : (
            <div className="space-y-4">
              <input type="file" onChange={handleFileChange} />
              <button
                onClick={handleUpload}
                className="w-full bg-[#900028] text-white py-2 px-4 rounded-md shadow-sm"
              >
                Upload File
              </button>
              <button
                onClick={downloadTemplate}
                className="mt-2 bg-[#FFCCEA] text-gray-700 py-1 px-1.5 rounded-md shadow-sm text-sm"
              >
                Download Template
              </button>
            </div>
          )}

          {error && <p className="mt-4 text-center text-red-500">{error}</p>}
          {success && <p className="mt-4 text-center text-green-500">{success}</p>}

          {/* Step 3: Render invalid emails in a table */}
          {invalidEmails.length > 0 && (
            <div className="mt-6">
              <h3 className="text-lg font-semibold">Invalid Emails</h3>
              <table className="min-w-full bg-white border border-gray-300">
                <thead>
                  <tr>
                    <th className="border px-4 py-2">Email</th>
                    <th className="border px-4 py-2">Error</th>
                  </tr>
                </thead>
                <tbody>
                  {invalidEmails.map((entry, index) => (
                    <tr key={index}>
                      <td className="border px-4 py-2">{entry.email}</td>
                      <td className="border px-4 py-2">{entry.error}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {loading && <LoadingSpinner />}
    </div>
  );
};

export default AddAppUser;




