import React, { createContext, useContext, useState } from 'react';
import { auth, firestore } from '../lib/firebase';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { collection, query, where, getDocs, setDoc, doc, getDoc } from 'firebase/firestore';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail as firebaseSendPasswordResetEmail } from 'firebase/auth';
import { setCookie , removeCookie , getCookie } from '../utils/cookieUtil';
import { v4 as uuidv4 } from 'uuid'; 



export const checkEmailExist = async (email) => {
  try {
    const signInMethods = await fetchSignInMethodsForEmail(auth, email);
    return signInMethods;
  } catch (error) {
    console.error('Error fetching sign-in methods:', error);
    throw error;
  }
};

export const isAdminUser = async (email) => {
  try {
    const adminUsersRef = collection(firestore, 'AdminUsers');
    const q = query(adminUsersRef, where('email', '==', email));
    const snapshot = await getDocs(q);
    return !snapshot.empty;
  } catch (error) {
    console.error('Error checking admin user:', error);
    throw new Error('Error checking admin user');
  }
};

export const getAdminUserDetails = async (email) => {
  try {
    const adminUsersRef = collection(firestore, 'AdminUsers');
    const q = query(adminUsersRef, where('email', '==', email));
    const snapshot = await getDocs(q);

    if (snapshot.empty) {
      throw new Error('Admin user not found');
    }

    const userDoc = snapshot.docs[0].data();
    return {
      firstName: userDoc.firstName,
      lastName: userDoc.lastName,
      roleId:userDoc.roleId
    };
  } catch (error) {
    console.error('Error getting admin user details:', error);
    throw new Error('Error getting admin user details');
  }
};


const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(getCookie('user') || null); 

  const logoutUser = async () => {
    try {
      console.log("Starting logout process...");

      removeCookie('user');
      setUser(null);
      console.log("User cookie removed.");

      await auth.signOut();
      console.log("User signed out successfully.");

      window.location.reload();
    } catch (error) {
      console.error('Error logging out:', error);
      throw new Error('Logout failed');
    }
  };

  return (
    <UserContext.Provider value={{ user, setUser, logoutUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);


export const loginUser = async (email, password,navigate) => {
  try {
    const isAdmin = await isAdminUser(email);
    if (isAdmin) {
      await signInWithEmailAndPassword(auth, email, password);
      const userDetails = await getAdminUserDetails(email);
      if(userDetails){
        setCookie('user', userDetails);
        const roleId=userDetails.roleId;
        try {
          const rolesRef = collection(firestore, 'roles');
          const q = query(rolesRef, where('roleId', '==', roleId));
          const snapshot = await getDocs(q);
      
          if (snapshot.empty) {
            throw new Error('Admin user not found');
          }
      
          const userDoc = snapshot.docs[0].data();
          setCookie('roleId', userDoc.permissionList);
          
        } catch (error) {
          console.error('Error getting admin user details:', error);
          throw new Error('Error getting admin user details');
        }
        
        navigate('/app');
        window.location.reload()
      }
      
    } else {
      throw new Error('User is not an admin');
    }
  } catch (error) {
    console.error('Error logging in:', error);
    throw new Error('Login failed');
  }
};

export const createAdminUser = async (email, password, firstName, lastName) => {
  try {

    var appgroup =null;

    const userExistsInAuth = await checkEmailExist(email);

    if (userExistsInAuth.length === 0) {
console.log('1111111111');

    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

    const UsersListRef = collection(firestore, 'UsersList');
    const emailQuery = query(UsersListRef, where('email', '==', email));
    const querySnapshot = await getDocs(emailQuery);

    if(querySnapshot.empty){

    await setDoc(doc(firestore, 'AdminUsers', user.uid), {
      userId: user.uid,
      email,
      firstName,
      lastName,
      password,
      role:"Admin",
      roleId:"1AZhOiCk2sQ8cZcWy0iu"
    });
      
    return { success: true, userId: user.uid, newUser:true };

  } else {
console.log("222222222222222222222222");

    querySnapshot.forEach((doc) => {
      appgroup=  doc.data().group;
      
    });

console.log("333333333333333333333333333======appgroup",appgroup);


    await setDoc(doc(firestore, 'AdminUsers', user.uid), {
      userId: user.uid,
      email,
      firstName,
      lastName,
      password,
      role:"Admin",
      roleId:"1AZhOiCk2sQ8cZcWy0iu"
    });
console.log("4444444444444444");

    await setDoc(doc(firestore, 'Users', user.uid), {
      email: email,
      group: appgroup,
      name: {
      firstName: firstName,
      secondName: lastName 
      },
      temp_password_changed: true, 
      userId: user.uid
    });
console.log("55555555555555555");

    return { success: true, userId: user.uid, newUser:true };
  }

  } else {

        const adminUsersRef = collection(firestore, 'AdminUsers');
        const emailQuery = query(adminUsersRef, where('email', '==', email));
        const querySnapshot = await getDocs(emailQuery);

        if(querySnapshot.empty) {

        const randomId = uuidv4(); 
        
        await setDoc(doc(firestore, 'AdminUsers', randomId), {
        userId:randomId,
        email,
        firstName,
        lastName,
        role:"Admin",
        roleId:"1AZhOiCk2sQ8cZcWy0iu"
        });
        
        return { success: true, message: 'User data updated with random ID.',newUser:false };
        } else{
          return { success: false, message: 'User already exists.' };
        }
        
  }

} catch (innerError) {
          return { success: false, error: innerError.message };
        }
};

//   try {
//     const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//     const user = userCredential.user;

//     await setDoc(doc(firestore, 'AdminUsers', user.uid), {
//       userId: user.uid,
//       email,
//       firstName,
//       lastName,
//       password,
//       role:"Admin",
//       roleId:"1AZhOiCk2sQ8cZcWy0iu"
//     });

//     await setDoc(doc(firestore, 'UsersList', user.uid), {
//       email: email,
//       group: "A",
//       firstName: firstName,
//       secondName: lastName 
//     });

//     await setDoc(doc(firestore, 'Users', user.uid), {
//       email: email,
//       group: "A",
//       name: {
//         firstName: firstName,
//         secondName: lastName 
//       },
//       temp_password_changed: true, 
//       uid: user.uid
//     });
    

//     return { success: true, userId: user.uid, newUser:true };
//   } catch (error) {
//     if (error.code === 'auth/email-already-in-use') {
//       try {

//         const adminUsersRef = collection(firestore, 'AdminUsers');
//         const emailQuery = query(adminUsersRef, where('email', '==', email));
//         const querySnapshot = await getDocs(emailQuery);
        

// if(querySnapshot.empty){

//   const randomId = uuidv4(); 
  
//   await setDoc(doc(firestore, 'AdminUsers', randomId), {
//   userId:randomId,
//   email,
//   firstName,
//   lastName,
//   role:"Admin",
//   roleId:"1AZhOiCk2sQ8cZcWy0iu"
//   });
  
//   return { success: true, message: 'User data updated with random ID.',newUser:false };
// }else{
//   return { success: false, message: 'User already exists.' };
// }

//       } catch (innerError) {
//         return { success: false, error: innerError.message };
//       }
//     } else {
//       return { success: false, error: error.message };
//     }
//   }


export const createFaculty = async (email, password, firstName, lastName) => {
  try {
    var appgroup =null;

    const userExistsInAuth = await checkEmailExist(email);

    if (userExistsInAuth.length === 0) {

    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;

   
    const UsersListRef = collection(firestore, 'UsersList');
    const emailQuery = query(UsersListRef, where('email', '==', email));
    const querySnapshot = await getDocs(emailQuery);

    

    if(querySnapshot.empty){

      await setDoc(doc(firestore, 'AdminUsers', user.uid), {
        userId: user.uid,
        email,
        firstName,
        lastName,
        password,
        role:"faculty",
        roleId:"ZZQVXbqjZJVd6V9BNcjq"
      });
        
      return { success: true, userId: user.uid, newUser:true };  

  } else {
    querySnapshot.forEach((doc) => {
      appgroup = doc.data().group;
    });
    
    await setDoc(doc(firestore, 'AdminUsers', user.uid), {
      userId: user.uid,
      email,
      firstName,
      lastName,
      password,
      role:"faculty",
      roleId:"ZZQVXbqjZJVd6V9BNcjq"
    });

    await setDoc(doc(firestore, 'Users', user.uid), {
      email: email,
      group: appgroup,
      name: {
      firstName: firstName,
      secondName: lastName 
      },
      temp_password_changed: true, 
      userId: user.uid
    });

    return { success: true, userId: user.uid, newUser:true };  
  }
  } else {

        const adminUsersRef = collection(firestore, 'AdminUsers');
        const emailQuery = query(adminUsersRef, where('email', '==', email));
        const querySnapshot = await getDocs(emailQuery);

        if(querySnapshot.empty) {

        const randomId = uuidv4(); 
        
        await setDoc(doc(firestore, 'AdminUsers', randomId), {
        userId:randomId,
        email,
        firstName,
        lastName,
        role:"faculty",
        roleId:"ZZQVXbqjZJVd6V9BNcjq"
        });
        
        return { success: true, message: 'User data updated with random ID.',newUser:false };
        } else{
          return { success: false, message: 'User already exists.' };
        }
        
  }

} catch (innerError) {
          return { success: false, error: innerError.message };
        }
};





// export const createFaculty = async (email, password, firstName, lastName) => {
//   try {
//     const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//     const user = userCredential.user;

//     await setDoc(doc(firestore, 'AdminUsers', user.uid), {
//       userId: user.uid,
//       email,
//       firstName,
//       lastName,
//       password,
//       role:"faculty",
//       roleId:"ZZQVXbqjZJVd6V9BNcjq"
//     });

//     await setDoc(doc(firestore, 'UsersList', user.uid), {
//       email: email,
//       group: "A",
//       firstName: firstName,
//       secondName: lastName 
//     });

//     await setDoc(doc(firestore, 'Users', user.uid), {
//       email: email,
//       group: "A",
//       name: {
//         firstName: firstName,
//         secondName: lastName 
//       },
//       temp_password_changed: true, 
//       uid: user.uid
//     });
    

//     return { success: true, userId: user.uid, newUser:true };
//   } catch (error) {
//     if (error.code === 'auth/email-already-in-use') {
//       try {

//         const adminUsersRef = collection(firestore, 'AdminUsers');
//         const emailQuery = query(adminUsersRef, where('email', '==', email));
//         const querySnapshot = await getDocs(emailQuery);
        

// if(querySnapshot.empty){

//   const randomId = uuidv4(); 
  
//   await setDoc(doc(firestore, 'AdminUsers', randomId), {
//   userId:randomId,
//   email,
//   firstName,
//   lastName,
//   role:"faculty",
//   roleId:"ZZQVXbqjZJVd6V9BNcjq"
//   });
  
//   return { success: true, message: 'User data updated with random ID.',newUser:false };
// }else{
//   return { success: false, message: 'User already exists.' };
// }

//       } catch (innerError) {
//         return { success: false, error: innerError.message };
//       }
//     } else {
//       return { success: false, error: error.message };
//     }
//   }
// };



export const createAppUser = async (email, firstName, secondName, group) => {
    var adminUid=null;
  try {

    const userExistsInAuth = await checkEmailExist(email);
    if (userExistsInAuth.length === 0) {
      
      const UsersListRef = collection(firestore, 'UsersList');
      const emailQuery = query(UsersListRef, where('email', '==', email));
      const querySnapshot = await getDocs(emailQuery);

      const randomId = uuidv4(); 
      
      if(querySnapshot.empty){
      await setDoc(doc(firestore, 'UsersList', randomId), {
        email,
        firstName,
        secondName,
        group
      });
      return { success: true, message: 'User created successfully.', newUser:true };
    } else{
      return { success: false, message: 'User already exists.' };
    }
   
    } else {
    const AdminuidRef = collection(firestore, 'AdminUsers');
    const emailQueryuid = query(AdminuidRef, where('email', '==', email));
    const querySnapshotuid = await getDocs(emailQueryuid);

    if (querySnapshotuid.empty) {
      console.log("No matching documents found.");
      return null;
    }

    querySnapshotuid.forEach((doc) => {
      adminUid=doc.id
      // setAdminuid(doc.id)
    });
      


      const UsersListRef = collection(firestore, 'UsersList');
      const emailQuery = query(UsersListRef, where('email', '==', email));
      const querySnapshot = await getDocs(emailQuery);
      
      
      if(querySnapshot.empty){
      await setDoc(doc(firestore, 'UsersList', adminUid), {
      email: email,
      group: group,
      firstName: firstName,
      secondName: secondName 
    });

    await setDoc(doc(firestore, 'Users', adminUid), {
      email: email,
      group: group,
      name: {
      firstName: firstName,
      secondName: secondName 
      },
      temp_password_changed: true, 
      uid: adminUid
    });
      return { success: true, message: 'User created successfully.', newUser:false };
    } else{
      return { success: false, message: 'User already exists.' };
    }
        
  }
   
  } catch (error) {
    return { success: false, error: error.message };
  }

};




// export const createAppUser = async (email, firstName, secondName, group) => {
//   try {
//     const adminUsersRef = collection(firestore, 'UsersList');
//     const emailQuery = query(adminUsersRef, where('email', '==', email));
//     const querySnapshot = await getDocs(emailQuery);
    
//     if(querySnapshot.empty){
//       const randomId = uuidv4(); 
//     await setDoc(doc(firestore, 'UsersList', randomId), {
//       email,
//       firstName,
//       secondName,
//       group
//     });

    

//     return { success: true, message: 'User created successfully.' };
//   } else{
//     return { success: false, message: 'User already exists.' };
//   }
//   } catch (error) {
//     return { success: false, error: error.message };
//   }

// };


export const sendPasswordResetEmail = async (email) => {
  try {
    await firebaseSendPasswordResetEmail(auth, email);
    return { success: true };
  } catch (error) {
    console.error('Error sending password reset email:', error);
    return { success: false, error: error.message };
  }
};



export async function GET() {
  try {
    const snapshot = await getDocs(collection(firestore, 'Users'));
    const users = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    

    return new Response(JSON.stringify({ users }), {
      status: 200,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    console.log(error);

    return new Response(JSON.stringify({ error: 'Failed to fetch users' }), {
      status: 500,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
